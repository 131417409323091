<template>
  <div class="container-preview-ad-service">

    <div class="row">
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 p-0 m-0 ad-service">
        <!-- No Results -->
        <div class="fw-bold pt-5 m-2" v-if="noData">No results to show <br/>Please choose service advertise</div>
        <!-- Loading -->
        <Loader class="mt-2" :loading="loading" :small="false"></Loader>
        <!-- Loop services -->
        <div v-if="formData && !loading" class="p-2">
          <div class="row ad-service-thumbnails">
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 p-0 m-0">
              <div id="carouselCaptionsPreviewAdService" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
                <ol class="carousel-indicators no-scroll d-none d-md-block" id="carouselThumbnailsPreviewAdService">
                  <li v-if="formData.documents_service_images && formData.documents_service_images.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block img-fluid carousel-indicators-thumbnails"
                         alt="..."
                    >
                  </li>
                  <li v-for="(doc, num) in formData.documents_service_images"
                      :key="num"
                      :data-bs-target="'#carouselCaptionsPreviewAdService'"
                      :data-bs-slide-to="num"
                      aria-current="true"
                      :aria-label="'Slide '+num"
                      :class="num === 0 ? 'active' : ''"
                      :id="'carousel-thumb-'+num"
                  >
                    <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                         class="d-block img-fluid carousel-indicators-thumbnails"
                         alt="..."
                    >
<!--                    <img v-if="doc" :src="doc" class="d-block img-fluid carousel-indicators-thumbnails" />-->
                  </li>
                </ol>
                <!--                :class="index === activeCarouselIndex ? 'active carousel-item' : 'carousel-item'"-->
                <div class="carousel-inner" id="adDetailItems">
                  <div v-if="formData.documents_service_images && formData.documents_service_images.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block w-100 img-fluid thumbnails-height"
                         alt="..."
                    >
                  </div>
                  <div v-for="(doc, index) in formData.documents_service_images"
                       :key="index"
                       :class="index === 0 ? 'active carousel-item' : 'carousel-item'"
                       :id="'carousel-inner-'+index"
                  >
                    <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                         class="d-block w-100 img-fluid thumbnails-height"
                         alt="..."
                    >
<!--                    <img v-if="doc" :src="doc" class="d-block w-100 img-fluid thumbnails-height" />-->
                  </div>
                </div>

                <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptionsPreviewAdService'" data-bs-slide="prev"> <!-- v-on:click="setActive(activeCarouselIndex-1)" -->
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptionsPreviewAdService'" data-bs-slide="next"> <!-- v-on:click="setActive(activeCarouselIndex+1)" -->
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>

              </div>
            </div>
          </div>
          <div class="col-12">
            <span aria-hidden="true" class="scroll-through-icon-span" v-on:click="scrollDetailsIntoView"><font-awesome-icon icon="chevron-circle-down" class="scroll-through-icon"/></span>
          </div>
          <div class="col-12 mt-2 mb-2" id="scrollIntoViewPreview">
            <div class="row text-start">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div v-if="formData && formData.address" class="row service-info m-0 p-0">
                  <div class="text-start list-relative property-info-box p-0">
                    <!-- Show Mini Map with Marker -->
                    <div class="ps-1 pe-1">
                      <MiniMap :lat="formData.address.latitude" :lng="formData.address.longitude" :propCategory="'Service'" :customClass="'rounded-3'"></MiniMap>
                    </div>
                  </div>
                </div>
                <div class="row service-info m-0 p-0 pt-1 pb-1">
                  <div class="text-start position-relative service-info-box p-0">
                    <div class="text-start service-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                      <span class="fw-bold">{{ formData.occupation ? formData.occupation.type.toUpperCase() : '' }}</span>
                    </div>
                  </div>
                </div>
                <div class="row service-info m-0 p-0 pt-1 pb-1">
                  <div class="text-start list-relative service-info-box p-0">
                    <div v-if="formData.address" class="service-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                      <span class="fw-bold">Area Covered:</span>
                      {{
                        (formData.address.county ? formData.address.county : '') +
                        (formData.address.city ? ', ' + formData.address.city : '') +
                        (formData.address.line_1 ? ', ' + formData.address.line_1 : '') +
                        (formData.address.postcode ? ', ' + formData.address.postcode : '')
                      }}
                    </div>
                  </div>
                </div>
                <div class="row service-info m-0 p-0 pt-1 pb-1">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                    <div class="text-start position-relative service-info-box p-0">
                      <div class="text-start service-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                        <span class="fw-bold">Posted:</span>
                        {{ calculateCreatedDate(formData.created_at) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row service-info m-0 p-0 pt-1 pb-1">
                  <div class="text-start position-relative service-info-box p-0">
                    <div class="text-start service-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                      <font-awesome-icon icon="calendar-day" style="color: green;"/>
                      <span class="fw-bold">Working Hours:</span>
                      {{ formData.working_hours_from_flexible ? 'Flexible' : (moment.utc(formData.working_hours_from).format('HH:mm')) }} - {{ formData.working_hours_to_flexible ? 'Flexible' : (moment.utc(formData.working_hours_to).format('HH:mm')) }}
                    </div>
                  </div>
                </div>

                <!-- Start Favourites, Share and Report -->
                <div class="row service-info m-0 p-0 mt-2">
<!--                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">-->
<!--                    <div class="text-center position-relative service-info-box p-0">-->
<!--                      <div class="text-center service-info-type ps-1 pe-1">-->
<!--                        <AddBookmark v-if="serviceDetails.is_published" style="font-size: 30px;" :id="serviceDetails.id" :type="'Property'" :tooltipClass="'tooltiptext-right'"></AddBookmark>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <div class="text-center position-relative service-info-box p-0">
                      <div class="text-center service-info-type ps-1 pe-1">
                        <Share :adId="id" :socials="setShareSocials()"></Share>
                        <span class="text-black-50 small">Share</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <div class="text-center position-relative service-info-box p-0">
                      <div class="text-center service-info-type ps-1 pe-1">
                        <!-- Start Action Buttons -->
                        <div class="action-dropdown">
                          <button @click.stop="preventParentClick()"
                                  class="action-button-report"
                                  type="button"
                                  :id="'actionServiceDropdownReportType'+formData.id"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside"
                                  aria-expanded="false"
                          >
                              <span data-tooltip="tooltip" class="m-0 p-0">
                                <div class="review-icons-container" style="font-size: 30px;">
                                  <div class="position-relative icon-wrapper">
                                    <font-awesome-icon icon="exclamation-triangle" class="icon-report" />
                                  </div>
                                </div>
                                <span class="tooltiptext">Report</span>
                              </span>
                          </button>
                          <!-- Report Type Dropdown -->
                          <ul class="dropdown-menu list-unstyled action-buttons" :aria-labelledby="'actionServiceDropdownReportType'+formData.id">
                            <li class="action-item">
                              <button :class="processing ? 'disabled action-button-spam' : 'action-button-spam'"
                                      @click.stop="reportAdvertise(formData.id, 'Service', 'Spam')"
                              >
                                <font-awesome-icon icon="clone" class="min-width-30 icon-spam" />
                                SPAM
                              </button>
                            </li>
                            <li class="action-item">
                              <button :class="processing ? 'disabled action-button-scam' : 'action-button-scam'"
                                      @click.stop="reportAdvertise(formData.id, 'Service', 'Scam')"
                              >
                                <font-awesome-icon icon="user-ninja" class="min-width-30 icon-scam" />
                                SCAM
                              </button>
                            </li>
                            <li class="action-item">
                              <button :class="processing ? 'disabled action-button-abuse' : 'action-button-abuse'"
                                      @click.stop="reportAdvertise(formData.id, 'Service', 'Abuse')"
                              >
                                <font-awesome-icon icon="heart-broken" class="min-width-30 icon-abuse" />
                                ABUSE
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <span class="text-black-50 small">Report</span>
                    </div>

                  </div>
                </div>

                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Reviews</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>

                <div class="row service-info m-0 p-0">
                    <span data-tooltip="tooltip" class="m-0 p-0">
                      <div
                          class="row m-0 p-0"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewsModal"
                          @click="sendReviewModal(formData, 'service', 'Reviews')"
                      >
                        <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                          <div class="text-start position-relative service-info-box p-0">
                            <div class="text-start service-info-type ps-1 pe-1">
                              <div class="review-icons-container" style="font-size: 30px;">
                                  <div class="position-relative icon-wrapper">
                                    <font-awesome-icon icon="users-cog" class="service-review" style="color: lightblue;"/>
                                    <font-awesome-icon icon="star" class="small-star" style="color: yellow;"/>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0">
                          <div class="text-start position-relative service-info-box p-0">
                            <div class="text-start service-info-type ps-1 pe-1 pt-3">
                              <!-- Use component here to display star rating -->
                              Rating: <ShowReviewRating :rating="calculateTotalRating(serviceReviews, 'service')" :showNumericRating="true"></ShowReviewRating>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span class="tooltiptext">Service Reviews</span>
                    </span>
                </div>
                <!-- End Favourites, Share and Report -->

              </div>

              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">

                <!-- CONTACT DETAILS - USER OR AGENCY IMAGE -->
                <div class="row m-0 p-0 text-start marketed-by">
                  <!-- USER OR AGENCY IMAGE -->
                  <p class="marketed-by-heading">Marketed By</p>
                  <div class="col-8 m-0 p-0 ps-2 service-info">
                    <div v-if="formData.contact.company_name && checkUserSettings(formData.settings, 1)" class="list-relative-contact">
                      <!-- setting_type_id 1 = Company Name -->
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="fw-bold">Company:</span>
                        {{ formData.contact.company_name }}
                      </div>
                    </div>
                    <div v-if="formData.contact.address && formData.contact.address.full_address && checkUserSettings(formData.settings, 2)" class="list-relative-contact">
                      <!-- setting_type_id 2 = Address -->
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="fw-bold">Address:</span>
                          {{ formData.contact.address.full_address }}
                      </div>
                    </div>
                    <div class="list-relative-contact">
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="fw-bold">Contact:</span>
                        {{ formData.contact.name ? formData.contact.name : 'Anonymous' }}
                      </div>
                    </div>
                    <ul class="list-unstyled">
                      <!-- Landing -->
                      <!-- setting_type_id 4 = Landing -->
                      <li v-if="formData.contact.landing && checkUserSettings(formData.settings, 4)">
                            <span data-tooltip="tooltip">
                              <div class="row m-0 p-0">
                                <div class="col-1 m-0 p-0 pt-1">
                                  <font-awesome-icon icon="phone" class="contact-icons"/>
                                </div>
                                <div class="col-11 m-0 p-0 pt-1">
                                  <a :href="'tel:'+calculateLanding(formData.contact)" alt="call" class="contact-url">
                                    {{ calculateLanding(formData.contact) }}
                                  </a>
                                  <span class="tooltiptext">Landing</span>
                                </div>
                              </div>
                            </span>
                      </li>
                      <!-- Mobile -->
                      <!-- setting_type_id 3 = Mobile -->
                      <li v-if="formData.contact.mobile && checkUserSettings(formData.settings, 3)">
                              <span data-tooltip="tooltip">
                                <div class="row m-0 p-0">
                                  <div class="col-1 m-0 p-0 pt-1">
                                    <font-awesome-icon icon="mobile-alt" class="contact-icons"/>
                                  </div>
                                  <div class="col-11 m-0 p-0 pt-1">
                                    <a :href="'tel:'+calculateMobile(formData.contact)" alt="call" class="contact-url">
                                      {{ calculateMobile(formData.contact) }}
                                    </a>
                                    <span class="tooltiptext">Mobile</span>
                                  </div>
                                </div>
                              </span>
                      </li>
                      <!-- Email -->
                      <!-- setting_type_id 5 = Email -->
                      <li v-if="formData.contact.email && checkUserSettings(formData.settings, 5)">
                              <span data-tooltip="tooltip">
                                <div class="row m-0 p-0">
                                  <div class="col-1 m-0 p-0 pt-1">
                                    <font-awesome-icon icon="envelope" class="contact-icons"/>
                                  </div>
                                  <div class="col-11 m-0 p-0 pt-1">
                                    <a :href="'mailto:'+formData.contact.email" alt="email" class="contact-url">
                                      {{ formData.contact.email }}
                                    </a>
                                    <span class="tooltiptext">Email</span>
                                  </div>
                                </div>
                              </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-4">
                    <div v-if="!formData.user.documents_user_images">
                      <img :src="require(`@/assets/no-user-image.png`)"
                           class="d-block img-fluid user-image"
                           alt="..."
                      >
                    </div>
                    <div v-else>
<!--                      <img :src="require(`@/assets/${this.$store.state.auth.user.documents_user_images.file_name}`)"-->
<!--                           class="d-block img-fluid user-image"-->
<!--                           alt="..."-->
<!--                      >-->
                      <img :src="`${$s3bucket}${formData.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                           class="d-block img-fluid user-image"
                           alt="..."
                      >
<!--                      <img v-if="getImage(formData.user.documents_user_images.file_name)" :src="require(`@/assets/${formData.user.documents_user_images.file_name}`)"-->
<!--                           class="d-block img-fluid user-image"-->
<!--                           alt="..."-->
<!--                      >-->
<!--                      <img v-else :src="require(`@/assets/no-image-found.png`)"-->
<!--                           class="d-block img-fluid user-image"-->
<!--                           alt="..."-->
<!--                      >-->
                    </div>
                  </div>

                  <hr class="p-0 m-0"/>
                  <div v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="row p-0 m-0">
                    <!-- Chat Message -->
                    <div v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="col-12 col-sm-12 col-md-6 col-lg-6 text-center p-2">
                      <button data-bs-toggle="modal"
                              data-bs-target="#chatModal"
                              class="chat-msg-button"
                              @click="sendModal(formData, 'Service', null, 'Chat')"
                      >
                        <font-awesome-icon icon="comment-dots" class="contact-icons chat-icon"/> Send Message
                      </button>
                    </div>

                    <!-- Book Slot -->
                    <div v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="col-12 col-sm-12 col-md-6 col-lg-6 text-center p-2"
                    >
                      <button
                          data-bs-toggle="modal"
                          data-bs-target="#bookingModal"
                          class="chat-msg-button"
                          @click="sendModal(formData, 'Service', 'appoint', 'Booking')"
                      >
                        <font-awesome-icon icon="calendar-day" class="contact-icons chat-icon"/> Book Slot
                      </button>
                    </div>
                  </div>

                  <div v-else class="row p-0 m-0">
                    <div class="text-center">
                      <small>
                        <router-link to="/login" class="text-decoration-none">
                          Login
                        </router-link>
                        to send private messages or book via platform
                      </small>
                    </div>
                  </div>

                </div>

              </div>

              </div>
            </div>

<!--            <div class="col-12 mt-2 mb-2">-->
<!--              <div class="row text-start">-->
<!--                &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                <div class="horizontal-separator-box">-->
<!--                  <div class="service-info-container">-->
<!--                    <div class="text-start list-relative"> &lt;!&ndash; ps-3 pb-2 &ndash;&gt;-->
<!--                      <div class="service-info text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">-->
<!--                        {{ formData.serviceCatType + ' - ' + formData.full_address }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span aria-hidden="true" class="scroll-through-icon-span" v-on:click="scrollDetailsIntoView"><font-awesome-icon icon="chevron-circle-down" class="scroll-through-icon"/></span>-->
<!--                  <span class="hr-line-invisible"></span>-->
<!--                </div>-->
<!--                <div class="row service-info m-0 p-0 ps-1">-->
<!--                  Posted: {{ calculateCreatedDate(formData.created_at) }}-->
<!--                </div>-->
<!--                &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Service Details</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <div class="col-12 mt-2 mb-2">
              <div class="row text-start">
                <div class="row m-0 p-0 ps-2 pe-2">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="row m-0 p-0 service-icons">
                    <!-- ICONS -->

                    <!-- Start Top row icons: Price, Tenure -->
                    <div class="col-12">
                      <div class="row m-0 p-0 top-row-icons">
                        <div class="col-12">
                          <div class="row m-0 p-0">
                            <div class="col-1 m-0 p-0">
                              <ul class="list-unstyled">
                                <!-- Price -->
                                <li>
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="pound-sign" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Price</span>
                                </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-2">
                              <ul class="list-unstyled">
                                <!-- Price -->
                                <li class="list-relative">
                                  <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <span class="price-color"> {{ convertNumberToCommas(formData.price) }} </span>
                                    <span class="fw-bold"> {{ getServicePaymentType(formData.service_payment_type_id) }} </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <!-- End Top row icons: Price, Tenure -->

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Service Description Header -->
                    <div class="row text-center">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <p class="fw-bold m-0 p-0">Description</p>
                      </div>
                    </div>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Service Description Text -->
                    <div class="row text-start">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div id="servDescription" class="m-0 p-0"></div>
                      </div>
                    </div>

                </div>

              </div>

              <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div class="row m-0 p-0">

<!--                  <div class="col-12 mt-2">-->
<!--                    &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    &lt;!&ndash; End AdSense &ndash;&gt;-->
<!--                  </div>-->

                </div>
              </div>

<!--              &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--              <div class="horizontal-separator-box mt-4 mb-4">-->
<!--                <span class="hr-line"></span>-->
<!--                <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                <span class="hr-line"></span>-->
<!--              </div>-->
<!--              &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

<!--              &lt;!&ndash; Service Info Details &ndash;&gt;-->
<!--              <div class="row text-start">-->
<!--                <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">-->
<!--                  <ul class="list-unstyled">-->
<!--                    <li>-->
<!--                      <p class="fw-bold m-0 p-0">Description</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <div id="serviceDescription" class="m-0 p-0"></div> &lt;!&ndash; {{ formData.service_description }} &ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="m-0 p-0" v-html="formData.service_description"></div>&ndash;&gt;-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--                <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">-->
<!--                  <div class="row m-0 p-0">-->

<!--                    <div class="col-12 mt-2">-->
<!--                      &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--                      <AdSense></AdSense>-->
<!--                      <AdSense></AdSense>-->
<!--                      <AdSense></AdSense>-->
<!--                      &lt;!&ndash; End AdSense &ndash;&gt;-->
<!--                    </div>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->


            </div>
          </div>
        </div>

        </div>
        <!-- End Loop services -->
        <a href="javascript:void(0)" id="propDetailsModalCloseButton" class="closebtn" v-on:click="">
          <font-awesome-icon icon="angle-double-left" id="adDetailsChevronCloseBtn" class="m-0 p-0" />
<!--          <span data-tooltip="tooltip">-->
<!--            <font-awesome-icon icon="chevron-circle-left" id="adDetailsChevronCloseBtn"/>-->
<!--            <span class="tooltiptext">Close</span>-->
<!--          </span>-->
        </a>
      </div>

    </div>
    <div v-if="validationErrors && showError" class="global-error">
      <ValidationErrors :errors="validationErrors"/>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import Loader from "./Loader.vue"
import ChatModal from "./ChatModal"
import AdSense from "./AdSense"
import ValidationErrors from "./ValidationErrors"
import ShowReviewRating from "./Reviews/ShowReviewRating";
import Share from "./ShareSocials/Share";
import MiniMap from "./MiniMap";

export default {
  name: 'AdService',
  components: {
    'Loader': Loader,
    'ChatModal': ChatModal,
    'AdSense': AdSense,
    'ValidationErrors': ValidationErrors,
    'ShowReviewRating': ShowReviewRating,
    'Share': Share,
    'MiniMap': MiniMap
  },
  props: {
    id: Number
  },
  data(){
    return {
      user: this.$store.state.auth.user,
      formData: null,
      serviceReviews: '',
      showReportConfirmation: false,
      loading: false,
      noData: true,
      showError: false,
      validationErrors: null,
      processing: false
    }
  },
  mounted: function() {

  },
  computed: {

  },
  methods: {
    /* *** REVIEWS *** */
    calculateTotalRating(reviews, type) {
      if (reviews && reviews.length > 0) {
        let sum = 0;

        if (type && type == 'service') {
          reviews.forEach((review) => {
            sum = sum + ((review.review_communication + review.review_professionalism + review.review_value) / 3);
          });
        }
        if (type && type == 'neighbourhood') {
          reviews.forEach((review) => {
            sum = sum + ((review.review_amenities + review.review_crime + review.review_community + review.review_location) / 4);
          });
        }

        return Math.round(((sum / reviews.length) + Number.EPSILON) * 10) / 10;
      } else {
        return 0;
      }
    },
    async getServiceReviews() {
      this.processing = true;

      let data = new FormData();
      data.append("service_provider_id", this.formData.user.id);
      // data.append("ad_id", this.adId);
      data.append("service_type_occupation_id", this.formData.service_type_occupation_id);

      await axios.post('api/service-reviews', data).then(({data})=>{
        this.serviceReviews = data.data;
        this.validationErrors = null;
        this.validationMessage = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false;
      })
    },
    /* *** END REVIEWS *** */
    setShareSocials() {
      return {
        facebook: true,
        facebook_link: 'ad-service/' + this.id,
        twitter: true,
        twitter_link: 'ad-service/' + this.id,
        email: true,
        email_link: 'ad-service/' + this.id,
        linkedin: true,
        linkedin_link: 'ad-service/' + this.id,
        whatsapp: true,
        whatsapp_link: 'ad-service/' + this.id,
        facebook_messenger: true,
        facebook_messenger_link: 'ad-service/' + this.id,
        copy: true,
        copy_link: 'ad-service/' + this.id,
        tooltip_text: 'Share'
      };
    },
    async getServiceById(id) {
      this.noData = false;
      this.loading = true;
      // if(this.$store.state.services.services && this.$store.state.services.services.data) {
      //   for (let i = 0; i < this.$store.state.services.services.data.length; i++) {
      //     if (id === this.$store.state.services.services.data[i].id) {
      //       this.formData = this.$store.state.services.services.data[i];
      //     }
      //   }
      // }
      await axios.get('api/services/' + id).then(({data})=>{
        this.formData = data.data;
        this.loading = false;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
        }
        this.noData = true;
        // alert(response.data.message)
      }).finally(()=> {
        this.processing = false
        if (this.formData.service_description) {
          // Set Description Html Field
          this.parseHTML(this.formData.service_description, "servDescription");
        }
      })
    },
    sendReviewModal(adDetails, reviewType, modalType) {
      // Neighbourhood Necessary data:
      // adId, propertyDetails(address), reviewType (neighbourhood)
      // Service Necessary data:
      // adId, serviceDetails, reviewType (service), toUserId (service provider id), serviceOccupationId
      // Host Necessary data:
      // adId, propertyDetails, reviewType (host), toUserId (host id)
      // Guest Necessary data:
      // adId, propertyDetails, reviewType (guest), toUserId (guest id)
      let data = {
        adId: adDetails.id,
        adDetails: adDetails,
        toUserId: adDetails.user.id,
        reviewType: reviewType
      };
      this.$root.$emit('openModal', data, modalType);
    },
    // toggleReportType(id) {
    //   let el = document.getElementById("actionServiceDropdownReportTypeMenu"+id);
    //   if (el && el.classList.contains('showReportTypeMenu')) {
    //     el.classList.toggle('showReportTypeMenu');
    //     el.classList.toggle('show');
    //   } else if (el && !el.classList.contains('showReportTypeMenu')) {
    //     el.classList.toggle('showReportTypeMenu');
    //     el.classList.toggle('show');
    //   }
    // },
    async reportAdvertise(id, type, report_type) {

      if (!confirm("Are you sure you want to report this advertise?")) {
        return;
      }
      this.processing = true;

      // Create form: type should be either Property or Service
      let requestForm = new FormData();
      requestForm.append("id", id);
      requestForm.append("type", type);
      requestForm.append("report_type", report_type);

      await axios.post("api/report/", requestForm).then(response => {
        this.validationSuccess = response.data.success;
        this.showReportConfirmation = true;
        const timeout = setTimeout(() => {
          this.showReportConfirmation = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserProperties', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    // Loop user settings
    checkUserSettings(settings, setting_type_id) {
      for (let i=0; i < settings.length; i++) {
        if (settings[i].setting_type_id === setting_type_id) {
          if (settings[i].setting_value === '1') {
            return true;
          } else {
            return false;
          }
        }
      }
      // If there is no setting_type_id for that user, return false
      return false;
    },
    parseHTML(html, elementId) {
      let element = document.getElementById(elementId);
      element.innerHTML = '';
      element.insertAdjacentHTML('beforeend', html);
    },
    calculateMobile(user) {
      if(user.mobile_prefix && user.mobile_prefix !== '') {
        return user.mobile_prefix + ' ' + user.mobile;
      } else {
        return user.mobile;
      }
    },
    calculateLanding(user) {
      if(user.landing_prefix && user.landing_prefix !== '') {
        return user.landing_prefix + ' ' + user.landing;
      } else {
        return user.landing;
      }
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    getServicePaymentType(service_payment_type_id) {
      switch(service_payment_type_id) {
        case 2:
          return 'per hour';
          break;
        case 3:
          return 'per night';
          break;
        case 4:
          return 'per week';
          break;
        case 5:
          return 'per month';
          break;
        case 6:
          return 'per job';
          break;
        case 7:
          return 'per square meter';
          break;
        case 8:
          return 'per TBD';
          break;
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    scrollDetailsIntoView() {
      /* Use of double !! does not return dom element, just bool true/false */
      let doesElExist = !!document.getElementById("scrollIntoViewPreview");
      if(doesElExist) {
        document.getElementById("scrollIntoViewPreview").scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    },
    sendModal(serviceDetails, adType, booking = null, modalType) {
      let data = {
        adDetails: serviceDetails,
        toUserId: serviceDetails.user.id,
        fromUserId: null,
        adId: serviceDetails.id,
        adType: adType,
        booking: booking,
        message: null
      };
      if (modalType === 'Booking') {
        // We use below to refresh modal in App.vue by changing one of its bool keys
        this.$root.$emit('refreshModal', 'bookingModal');
      }
      this.$root.$emit('openModal', data, modalType);
    },
    /* I might not need that anymore, up there removed its trigger on prev and next slides */
    setActive(index) {
      /*
        As slides are counted from 0 to x slides, negative values would mean we want to
        end up on LAST possible slide, hence if index < 0 we count number of child elements (slides)
        and assign last number -1 (elements count might be 1-2-3, but slides counts from 0-1-2)

        This solution works, nevertheless FAST clicking on slide change interrupts it, I might need
        to set it as async and promise
      */
      let countChildren = document.getElementById('adDetailItems').childElementCount;

      if(countChildren) {
        switch (index) {
          case index < 0:
            // console.log('index < 0');
            // console.log('Index: ' + index);
            // console.log('Count children: ' + countChildren);
            this.activeCarouselIndex = countChildren - 1;
            // console.log(this.activeCarouselIndex);
            break;
          case (index >= 0 && index <= (countChildren - 1)):
            // console.log('index >= 0');
            // console.log('Index: ' + index);
            this.activeCarouselIndex = index;
            break;
          case index > (countChildren - 1):
            // console.log('index = '+ (countChildren));
            // console.log('Index: ' + index);
            this.activeCarouselIndex = 0;
            break;
        }
      }
    },
    /* I might not need that anymore, up there removed its trigger on prev and next slides */
    setActiveFloorplan(index) {
      /*
        As slides are counted from 0 to x slides, negative values would mean we want to
        end up on LAST possible slide, hence if index < 0 we count number of child elements (slides)
        and assign last number -1 (elements count might be 1-2-3, but slides counts from 0-1-2)

        This solution works, nevertheless FAST clicking on slide change interrupts it, I might need
        to set it as async and promise
      */
      let countFloorplansChildren = document.getElementById('floorplansItems').childElementCount;

      if(countFloorplansChildren) {
        switch (index) {
          case index < 0:
            // console.log('index < 0');
            // console.log('Index: ' + index);
            // console.log('Count children: ' + countChildren);
            this.activeCarouselFloorplansIndex = countFloorplansChildren - 1;
            // console.log(this.activeCarouselIndex);
            break;
          case (index >= 0 && index <= (countFloorplansChildren - 1)):
            // console.log('index >= 0');
            // console.log('Index: ' + index);
            this.activeCarouselFloorplansIndex = index;
            break;
          case index > (countFloorplansChildren - 1):
            // console.log('index = '+ (countChildren));
            // console.log('Index: ' + index);
            this.activeCarouselFloorplansIndex = 0;
            break;
        }
      }
    },
  },
  updated() {
    this.$nextTick(function () {
      // Code that will run only after the entire view has been re-rendered

      // /*
      //     AdDetail Slides carousel
      //     Cheating a bit, as v-for bootstrap 5 carousel seems to not be as reactive as I would like it to be.
      //     It does not reset v-for INDEX on pure carousel data change, so I had to use below functionality,
      //     so that I can remove all current 'active' class and set activeCarouselIndex back to 0 (start point)
      //    */
      // let carouselItems = document.getElementById('adDetailItems');
      // let countChildren = null;
      //
      // if(carouselItems != null) {
      //   countChildren = document.getElementById('adDetailItems').childElementCount;
      // }
      // if(carouselItems != null && countChildren > 0) {
      //   let i = 0;
      //   for(i; i<countChildren; i++) {
      //     let carouselElement = document.getElementById('carousel-inner-'+i);
      //     if(carouselElement) {
      //       if (document.getElementById('carousel-inner-' + i).classList.contains('active')) {
      //         document.getElementById('carousel-inner-' + i).classList.remove('active');
      //       }
      //     }
      //   }
      //
      //   let carouselFirstElement = document.getElementById('carousel-inner-0');
      //   if(carouselFirstElement) {
      //     if (!document.getElementById('carousel-inner-0').classList.contains('active')) {
      //       document.getElementById('carousel-inner-0').classList.add('active');
      //     }
      //   }
      // }
      //
      // this.activeCarouselIndex = 0;
      //
      // /* Floorplan carousel */
      // let carouselFloorplanItems = document.getElementById('floorplansItems');
      // let countFloorplanChildren = null;
      //
      // if(carouselFloorplanItems != null) {
      //   countFloorplanChildren = document.getElementById('floorplansItems').childElementCount;
      // }
      // if(carouselFloorplanItems != null && countFloorplanChildren > 0) {
      //   let i = 0;
      //   for(i; i<countFloorplanChildren; i++) {
      //     let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-'+i);
      //     if(carouselFloorplanElement) {
      //       if (document.getElementById('carousel-inner-floorplan-' + i).classList.contains('active')) {
      //         document.getElementById('carousel-inner-floorplan-' + i).classList.remove('active');
      //       }
      //     }
      //   }
      //
      //   let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-0');
      //   if(carouselFloorplanElement) {
      //     if (!document.getElementById('carousel-inner-floorplan-0').classList.contains('active')) {
      //       document.getElementById('carousel-inner-floorplan-0').classList.add('active');
      //     }
      //   }
      // }
      //
      // this.activeCarouselFloorplansIndex = 0;
    })
  },
  watch: {
    "id": {
      handler: async function (id) {
        if (id !== null) {
          this.loading = true;
          await this.getServiceById(this.$props.id);

          // Get reviews
          await this.getServiceReviews();
          this.loading = false;
        }
      }, deep: true, immediate: true,
    },
    "validationErrors": function (val) {
      if (val !== null) {
        this.showError = true;
        const timeout = setTimeout(() => {
          this.$root.$emit('globalRefreshUser');
          this.showError = false;
          if (this.validationErrors && this.validationErrors.ad_does_not_exist) {
            this.$emit('closePropDetailsModal');
          }
          clearTimeout(timeout);
        }, 4000);
        return;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

/* Start Text truncate - white space - for long texts and icons */
.list-relative-contact {
  width: 100%;
  position: relative;
  min-height: 25px;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item-square-meter {
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item-square-meter:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
}

.text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.user-image {
  width: 100%;
  height: 100%;
  max-width: 120px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
}

.service-info-container {
  width: 48%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.service-info-box {
  min-height: 20px;
}

.service-info-type {
  cursor: pointer;
  font-size: 13px;
}

.service-info {
  cursor: pointer;
  font-size: 13px;
  margin-left: -4%;
}

/* Start Review Icons */
.review-icons-container {
  width: 100%;
  height: auto;
  .icon-wrapper {
    width: fit-content;
    width: -moz-fit-content;
  }
}

.small-star {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: yellow;
  font-size: 12px;
  position: absolute;
  top: 4px;
  right: -6px;
}

.small-star path {
  stroke: black;
  stroke-width: 20;
}

.service-review {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: limegreen;
}

.service-review path {
  stroke: black;
  stroke-width: 20;
}

.service-review:hover {
  color: red;
  transform: scale(1.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.share-icon {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: limegreen;
}

.share-icon path {
  stroke: black;
  stroke-width: 20;
}

.share-icon:hover {
  color: red;
  transform: scale(1.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}
/* End Review Icons */

/* Start Action Buttons */
.ad-service .action-dropdown {
  z-index: 9999;
  min-height: 30px;
  text-align: center;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .icon-report {
    transform: scale(1);
    transition: 0.5s;
    -moz-transition: 0.5s;
    color: orange;
  }

  .icon-report path {
    stroke: black;
    stroke-width: 20;
  }

  .icon-report:hover {
    color: red;
    transform: scale(1.1);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report:hover .icon-report {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report .tooltiptext {
    position: absolute;
    top: -25px;
    width: max-content !important;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-spam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-spam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-spam:hover .icon-spam {
    color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-scam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-scam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-scam:hover .icon-scam {
    color: mediumblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-abuse {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-abuse:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-abuse:hover .icon-abuse {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.success-msg {
  position: fixed;
  top: 20%;
  left: 10%;
  z-index: 9999;
}

/* End Action Buttons */

.ad-service {
  position: absolute;
  z-index: 99;
  //background-color: rgba(255, 255, 255, 70%);
  a.closebtn {
    position: fixed;
    width: 28px;
    height: 28px;
    top: 46%;
    left: 31%;
    font-size: 24px;
    z-index: 9999;
    visibility: hidden;
    transition: 0.7s;
    color: #198754;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, .125);
    line-height: 0;
    background-color: rgba(255, 255, 255, 0.8);
    :hover {
      color: red;
      transition: 0.7s;
    }
  }
  a.closebtn .tooltiptext:hover {
    color: white !important;
  }
  a.closebtn #adDetailsChevronCloseBtn {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.container-preview-ad-service {
  width: 100%;
  height: 81vh;
  margin: auto;
  .row {
    margin: auto;
  }
  .col-6 {
    padding: 0;
  }
  .col-md-6 {
    padding: 0;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.ad-service-thumbnails {
  #carouselCaptionsPreviewAdService {
    .carousel-indicators {
      position: absolute;
      display: block;
      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;
      left: 80% !important;
      top: 0;
      overflow-x: scroll;
      .active {
        transition: 0.7s !important;
        opacity: 1 !important;
        background-color: rgba(255,255,255,0) !important;
      }
    }
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 10 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 10 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .carousel-indicators li {
      width: auto; // 200px
      height: auto; // 150px
      max-height: 180px;
      transition: 0.7s;
      opacity: 0.5;
      display: block;
      .carousel-indicators-thumbnails {
        width: 100%;
        height: auto;
        max-height: 200px;
        min-height: auto;
        //max-height: 150px;
        //min-height: 150px;
        border-radius: 5px;
      }
    }
    .thumbnails-height {
      width: 100%;
      height: auto;
      max-height: 500px;
      min-height: auto;
      //max-height: 550px;
      //min-height: 550px;
    }

    .carousel-inner {
      width: 79%;
      border-radius: 5px;
      height: auto;
      max-height: 500px;
      min-height: auto;
      //max-height: 550px;
      //min-height: 550px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 21%;
      width: 10%;
    }
  }
  #carouselPreviewFloorplans {
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 14 10'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 14 10'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .thumbnails-floorplan-height {
      width: 100%;
      height: auto;
      max-height: 400px;
      min-height: auto;
      //max-height: 250px;
      //min-height: 250px;
    }
    .carousel-inner {
      width: 100%;
      border-radius: 5px;
      height: auto;
      max-height: 400px;
      min-height: auto;
      //max-height: 250px;
      //min-height: 250px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      width: 10%;
    }
  }
  /* change transition duration to control the speed of fade effect */
  .carousel-item {
    transition: transform 1s ease-in-out;
  }

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: opacity 1s 1s;
  }
}
/*
  END
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.service-icons {
  font-size: 14px;
  --bs-gutter-x: 0;
  .col-1 {
    width: 14.33333333% !important;
  }
  .col-2 {
    width: 14.66666667% !important;
  }
  .top-row-icons {
    .col-2 {
      width: 80.66666667% !important;
    }
  }
}

.sub-icons-font {
  font-size: small;
}

ul.icons-listing li {
  margin: 5px 0 5px 0;
}

/* Start Contact Area */
.chat-modal {
  left: 0;
  top: 20%;
}

.marketed-by {
  border-radius: 10px;
  background: white;
  $border: 2px;
  position: relative;
  padding: 30% 2em;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    //background: linear-gradient(to bottom, #b209b2, #19b5fe);
    background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  }
  p.marketed-by-heading {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase
  }
  .chat-msg-button {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #19b5fe;
    background-color: white; // #19b5fe
    color: #19b5fe;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-msg-button:hover {
    background: rgba(128, 0, 128, 0.7);
    border: 1px solid rgba(128, 0, 128, 0.7);
    color: white;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-icon {
    color: #19b5fe;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-msg-button:hover .chat-icon {
    color: white;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
}

.contact-icons {
  font-size: 16px;
  color: darkblue;
}

a.contact-url {
  color: black;
  transition: 0.6s;
  text-decoration: none;
  :hover {
    transition: 0.6s;
    color: black;
  }
}

.list-horizontal {
  list-style-type: none;
  text-align: center;
  padding-inline-start: 0;
  li.list-item-horizontal {
    display: inline-block;
  }
}
/* End Contact Area */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-width: 499px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 150px !important;
    }
    .carousel-inner {
      min-height: 150px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 200px !important;
    }
    .carousel-inner {
      min-height: 200px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 500px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 580px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }

    .thumbnails-height {
      min-height: 250px !important;
    }

    .carousel-inner {
      min-height: 250px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 768px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }
    .thumbnails-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
      width: 79% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 21% !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 992px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 200px !important;
    }
    .carousel-inner {
      min-height: 200px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }
    .thumbnails-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 150px !important;
    }
    .thumbnails-height {
      min-height: 450px !important;
    }
    .carousel-inner {
      min-height: 450px !important;
    }
  }
}
@media screen and (min-width: 1920px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
  #carouselCaptionsPreviewAdService {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 150px !important;
    }
    .thumbnails-height {
      min-height: 550px !important;
    }
    .carousel-inner {
      min-height: 550px !important;
    }
  }
}

</style>
